import {
  type GetIssuesQueryParams,
  getIssues,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

import type { MachineContext } from '../Diagnosis.machine.types'
import { getCurrentPageData } from '../selectors/getCurrentPageData'

export const getCustomerIssues = async ({
  orderlineId,
  pageData,
}: {
  orderlineId: MachineContext['orderlineId']
  pageData: MachineContext['pageData']
}) => {
  const { logFeatureError } = useSalesCustomerCareLogger()
  const currentPageData = getCurrentPageData(pageData)

  let queryParams: GetIssuesQueryParams = {
    orderlineId: orderlineId as number,
  }
  let customerIssues = ''

  if (
    currentPageData?.selectedIssues &&
    !isEmpty(currentPageData?.selectedIssues)
  ) {
    customerIssues = currentPageData.selectedIssues
      .map((selectedIssue) => selectedIssue.customerIssue)
      .join(';')

    queryParams = {
      ...queryParams,
      customerIssues,
    }
  }

  const payload = await $httpFetch(getIssues, {
    queryParams: {
      ...queryParams,
    },
  })

  if (!isEmpty(payload)) {
    return payload
  }

  logFeatureError({
    errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} get issues service no issues found`,
    errorDetail: {
      orderline_id: orderlineId,
      customer_issues: customerIssues,
    },
    featureName: 'diagnosis_get_issues_service_no_issues_found',
  })

  throw new Error('No issues found')
}
