import type {
  IssueListResponse,
  IssueSibling,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import type { ProblemResponse } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/problemResponse'

export const PROGRESS_BAR_INCREMENT_PERCENTAGE = 15
export const PROGRESS_BAR_MAX_STEPS = 4

export const actors: Record<string, string> = {
  Modal: 'modal',
  Toast: 'toast',
}

export const events = {
  always: {
    type: '',
  } as {
    type: ''
    skipModal?: boolean
  },
  continueWithSelectedIssues: { type: 'CONTINUE_WITH_SELECTED_ISSUES' },
  doInvoiceRequest: {
    type: 'done.invoke.doInvoiceRequest',
    data: {} as unknown,
  },
  doInvoiceRequestFailed: {
    type: 'error.platform.doInvoiceRequest',
    data: {} as unknown,
  },
  getAction: {
    type: 'done.invoke.getAction',
    data: {} as ProblemResponse['action'],
  },
  getActionFailed: {
    type: 'error.platform.getAction',
    data: {} as unknown,
  },
  getIssues: {
    type: 'done.invoke.getIssues',
    data: {} as IssueListResponse,
  },
  getIssuesFailed: {
    type: 'error.platform.getIssues',
    data: {} as unknown,
  },
  goBack: { type: 'GO_BACK' },
  modalClosed: { type: 'MODAL_CLOSED' },
  retry: { type: 'RETRY' },
  selectIssues: {
    type: 'SELECT_ISSUES',
    selectedIssues: [],
  } as {
    type: 'SELECT_ISSUES'
    selectedIssues: IssueSibling[] | IssueSibling | null
    skipModal?: boolean
  },
  sendAnotherInvoiceRequest: { type: 'SEND_ANOTHER_INVOICE_REQUEST' },
  toastClosed: {
    type: 'TOAST_CLOSED',
  },
} as const

export const toasts = {
  RequestFailed: 'REQUEST_FAILED',
}
