<template>
  <div aria-hidden="true" class="relative h-6">
    <div
      class="border-t-static-default-low rounded-xs absolute bottom-0 h-6 w-full border-t-6"
    >
      <div
        class="border-t-static-default-hi rounded-sm absolute -top-6 border-t-6 ease-in-out motion-safe:transition-all motion-safe:duration-200"
        :style="progressStyle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { ProgressBarProps } from './ProgressBar.types'

const props = withDefaults(defineProps<ProgressBarProps>(), {
  currentValue: 0,
  max: 100,
})

const progressStyle = computed(() => ({
  width: `${
    100 * Math.max(Math.min(props.currentValue, props.max) / props.max, 0)
  }%`,
}))
</script>
