<template>
  <MinimalWithSideBarLayout
    :progress-bar-current-value="machineContext.progressBarCurrentValue"
    @back="goBack"
    @cancel="emit('cancel')"
  >
    <template #sidebar>
      <OrderlineRecapBlock
        :order-id="orderId"
        :product-image="productImage"
        :product-title="productTitle"
      />
    </template>

    <template #content>
      <IssueLayout
        v-if="!isMultipleIssues"
        class="md:mb-72"
        data-qa="diagnosis-issue-layout"
        data-test="diagnosis-issue-layout"
        :issue="issueData"
        @continue="onContinue"
      />

      <IssuesLayout
        v-if="isMultipleIssues"
        class="md:mb-72"
        data-qa="diagnosis-issues-layout"
        data-test="diagnosis-issues-layout"
        :issues="issuesData"
        @continue="onContinue"
      />
    </template>
  </MinimalWithSideBarLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import OrderlineRecapBlock from '@/scopes/customer-care/common/components/OrderlineRecapBlock/OrderlineRecapBlock.vue'
import MinimalWithSideBarLayout from '@/scopes/customer-care/common/layout/MinimalWithSideBarLayout.vue'

import { getCurrentPageData } from '../../machine/selectors/getCurrentPageData'

import type { PickIssuesScreenProps } from './PickIssuesScreen.types'
import IssueLayout from './components/IssueLayout/IssueLayout.vue'
import IssuesLayout from './components/IssuesLayout/IssuesLayout.vue'

const emit = defineEmits(['cancel'])

const props = defineProps<PickIssuesScreenProps>()

const stateChartIssues = computed(
  () => getCurrentPageData(props.machineContext.pageData)?.data,
)

const isMultipleIssues = computed(() => stateChartIssues.value.length > 1)

const issueData = computed(() => stateChartIssues.value[0])

const issuesData = computed(() =>
  stateChartIssues.value.filter((issue) => !isEmpty(issue.siblings)),
)

function goBack() {
  props.machineSend('GO_BACK')
}

function onContinue(event: IssueSibling | IssueSibling[]) {
  props.machineSend({
    type: 'SELECT_ISSUES',
    selectedIssues: event,
  })
}
</script>
