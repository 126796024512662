import { navigateTo } from '#imports'

import type { LinkInternal } from '@backmarket/http-api'
import {
  ISSUE_ACTION_TARGET,
  ISSUE_ACTION_TRIGGER_PROCESS_TARGET,
  ISSUE_ACTION_TYPE,
  type MobilePlanActionDetails,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/common'
import {
  CUSTOMER_ISSUE,
  type IssueListResponse,
  type IssueSibling,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import {
  type ServiceMap,
  type Typestate,
  assign,
  createMachine,
  log,
  raise,
  sendTo,
  spawn,
  stop,
} from 'xstate'

import { CARE_COMMON_MODALS_NAMES } from '@/scopes/care-commons/modals/names'
import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'
import { DASHBOARD } from '@/scopes/dashboard/routes'
import { INSURANCE as INSURANCE_ROUTES } from '@/scopes/insurance/routes.constants'

import { TOAST_EVENTS_TYPE } from '../../common/machine/Toast.constants'
import { toastMachine } from '../../common/machine/Toast.machine'
import { logServiceError } from '../../common/machine/actions/logError'
import { modalCallbackActor } from '../../common/machine/actors/Modal/Modal.actor'
import { MODAL_EVENTS_TYPE } from '../../common/machine/actors/Modal/Modal.constants'
import { isInvalidProblemErrorType } from '../../common/machine/guards/errors'
import {
  type MultipleCondType,
  every,
  some,
} from '../../common/machine/guards/guards'
import { encodeIssues } from '../../common/utils/issueCommunication'
import { CUSTOMER_CARE } from '../../route-names'
import { DIAGNOSIS_MODALS_NAMES } from '../modals/name'

import {
  PROGRESS_BAR_INCREMENT_PERCENTAGE,
  PROGRESS_BAR_MAX_STEPS,
  actors,
  toasts,
} from './Diagnosis.machine.constants'
import type {
  MachineContext,
  MachineEvents,
  MachineStateSchema,
} from './Diagnosis.machine.types'
import {
  getAllSelectedCustomerIssuesWithAction,
  getAllSelectedIssuesWithAction,
} from './selectors/getAllSelectedIssues'
import { getCurrentPageData } from './selectors/getCurrentPageData'
import { getIMEI } from './selectors/getIMEI'
import { doInvoiceRequest } from './services/doInvoiceRequest'
import { getAction } from './services/getAction'
import { getCustomerIssues } from './services/getIssues'
import { lastEvent } from './utils'

export function createDiagnosisMachine(initialState: string | null) {
  return createMachine<
    MachineContext,
    MachineEvents,
    Typestate<MachineContext>,
    ServiceMap,
    MachineStateSchema
  >(
    {
      id: 'diagnosis',
      preserveActionOrder: true,
      predictableActionArguments: true,
      initial: initialState ?? 'LOADING',
      schema: {
        context: {} as MachineContext,
        events: {} as MachineEvents,
      },

      context: {
        pageData: [],
        issueAction: null,
        orderId: null,
        orderlineId: null,
        mobileServicePartnerProviderLabel: null,
        mobileServicePartnerProviderUrl: null,
        modalActor: null,
        progressBarCurrentValue: 0,
        toastActor: null,
      },
      entry: 'initActors',
      exit: [stop(actors.Modal), stop(actors.Toast)],
      on: {
        TOAST_CLOSED: {
          actions: sendTo(actors.Toast, {
            type: TOAST_EVENTS_TYPE.closedToast,
          }),
        },
      },
      states: {
        LOADING: {
          id: 'LOADING',
          tags: 'loading',
          invoke: {
            id: 'getIssues',
            src: 'getIssues',
            onDone: {
              actions: ['addPageData', 'increaseProgressBar'],
              target: 'CHECK_FOR_ISSUE_ELIGIBILITY',
            },
            onError: [
              {
                cond: 'isInvalidProblemErrorType',
                target: 'LOADING_PROBLEM_CONTEXT_FAILED',
              },
              {
                actions: 'logErrorFromGetIssueService',
                target: 'LOADING_FAILED',
              },
            ],
          },
        },
        LOADING_FAILED: {
          id: 'LOADING_FAILED',
          tags: 'loading_failed',
          on: {
            RETRY: {
              target: '#LOADING',
            },
          },
        },
        LOADING_PROBLEM_CONTEXT_FAILED: {
          id: 'LOADING_PROBLEM_CONTEXT_FAILED',
          tags: 'loading_problem_context_failed',
          type: 'final',
        },
        CHECK_FOR_ISSUE_ELIGIBILITY: {
          id: 'CHECK_FOR_ISSUE_ELIGIBILITY',
          tags: 'loading',
          always: [
            {
              actions: 'storeRootAction',
              cond: every('isRootIssue', 'hasActionOnRootIssue'),
              target: '#PERFORM_ACTION',
            },
            {
              target: 'ISSUE_SELECTION',
            },
          ],
        },

        ISSUE_SELECTION: {
          id: 'ISSUE_SELECTION',
          initial: 'IDLE',
          on: {
            GO_BACK: [
              {
                cond: 'isRootIssue',
                target: '#LEAVE',
              },
              {
                actions: ['decreaseProgressBar', 'removeLastHistoryIssues'],
              },
            ],
            MODAL_CLOSED: {
              actions: 'resetCurrentSelection',
            },
            SEND_ANOTHER_INVOICE_REQUEST: {
              actions: raise(({ pageData }) => {
                const currentPageData = getCurrentPageData(pageData)

                return {
                  type: 'SELECT_ISSUES',
                  selectedIssues: currentPageData.selectedIssues,
                  skipModal: true,
                }
              }),
            },
            CONTINUE_WITH_SELECTED_ISSUES: {
              target: '#SINGLE_ISSUE',
            },
            SELECT_ISSUES: [
              {
                cond: 'isInsurerPortalAction',
                actions: ['openModalInsurerPortal', 'storeSelectedIssues'],
              },
              {
                cond: 'isTargetInvoiceRequestCanceled',
                actions: 'openModalInvoiceRequestCanceled',
              },
              {
                cond: 'isTargetPreShippingInvoiceRequest',
                actions: 'openModalPreShippingInvoiceRequest',
              },
              {
                cond: 'isRedirectToPartnerPortalAction',
                actions: [
                  'storeMobilePlanProvider',
                  'openModalMobileServiceRedirect',
                ],
              },
              {
                cond: some('isTargetShowImei', 'isTargetImeiSelfCare'),
                actions: [
                  'storeSelectedIssues',
                  'storeIssueAction',
                  'openModalImei',
                ],
              },
              {
                actions: 'storeSelectedIssues',
                cond: 'hasMultipleIssuesSelected',
                target: '#MULTIPLE_ISSUES',
              },
              {
                actions: 'storeSelectedIssues',
                target: '#SINGLE_ISSUE',
              },
            ],
          },
          states: {
            IDLE: {
              id: 'IDLE',
            },
            SINGLE_ISSUE: {
              id: 'SINGLE_ISSUE',
              always: [
                {
                  actions: 'storeIssueAction',
                  cond: every(
                    'hasSelectedIssuesWithActions',
                    'hasSameTargetForAllSelectedIssues',
                  ),
                  target: '#PERFORM_ACTION',
                },
                {
                  cond: 'hasSelectedIssuesWithActions',
                  target: '#GET_ACTION_TO_PERFORM',
                },
                {
                  target: '#LOADING',
                },
              ],
            },
            MULTIPLE_ISSUES: {
              id: 'MULTIPLE_ISSUES',
              always: [
                {
                  actions: 'storeIssueAction',
                  cond: every(
                    'hasSelectedIssuesWithActions',
                    'hasSameTargetForAllSelectedIssues',
                  ),
                  target: '#PERFORM_ACTION',
                },
                {
                  cond: 'hasSelectedIssuesWithActions',
                  target: '#GET_ACTION_TO_PERFORM',
                },
                {
                  target: '#LOADING',
                },
              ],
            },

            PERFORM_ACTION: {
              id: 'PERFORM_ACTION',
              tags: 'loading',
              always: [
                {
                  cond: 'isExternalRedirectAction',
                  target: '#TO_EXTERNAL_LINK',
                },
                {
                  cond: 'isTargetInvoiceRequestPending',
                  target: '#TO_RESOLUTION_FLOW',
                },

                {
                  cond: some(
                    'isTargetShowImei',
                    'isTargetImeiSelfCare',
                    'isTargetBmWarrantyClaim',
                    'isTargetGetResolutionOptions',
                    'isTargetGetResolutionOptionsWithoutWarrantyExclusion',
                    'isTargetOrderlineLifecycle',
                    'isTargetRemoteAssistance',
                  ),

                  target: '#TO_RESOLUTION_FLOW',
                },
                {
                  cond: 'isTargetInsuranceCancellation',
                  target: '#TO_INSURANCE_CANCELLATION',
                },
                {
                  cond: 'isTargetInsurancePayment',
                  target: '#TO_INSURANCE_PAYMENT',
                },
                {
                  cond: 'isTargetInvoiceRequest',
                  target: '#REQUEST_INVOICE',
                },

                {
                  actions: 'openModalInvoiceRequestPending',
                  cond: 'isTargetInvoiceRequestPendingModal',
                  target: '#IDLE',
                },
                {
                  cond: 'isTargetMyOrders',
                  target: '#TO_MY_ORDERS',
                },
                {
                  cond: 'isTargetMySales',
                  target: '#TO_MY_SALES',
                },
                {
                  actions: ['logUnhandledAction', 'resetCurrentSelection'],
                  target: '#IDLE',
                },
              ],
            },
            TO_INSURANCE_CANCELLATION: {
              id: 'TO_INSURANCE_CANCELLATION',
              entry: 'navigateToInsuranceCancellation',
            },
            TO_INSURANCE_PAYMENT: {
              id: 'TO_INSURANCE_PAYMENT',
              entry: 'navigateToInsurancePayment',
            },
            TO_MY_ORDERS: {
              id: 'TO_MY_ORDERS',
              entry: 'navigateToMyOrders',
            },
            TO_MY_SALES: {
              id: 'TO_MY_SALES',
              entry: 'navigateToMySales',
            },
            TO_EXTERNAL_LINK: {
              id: 'TO_EXTERNAL_LINK',
              entry: 'navigateToExternalLink',
            },
            TO_RESOLUTION_FLOW: {
              id: 'TO_RESOLUTION_FLOW',
              entry: 'navigateToResolutionFlow',
            },
            LEAVE: {
              id: 'LEAVE',
              entry: 'navigateToMyOrders',
            },
          },
        },

        GET_ACTION_TO_PERFORM: {
          id: 'GET_ACTION_TO_PERFORM',
          initial: 'LOADING',
          states: {
            LOADING: {
              tags: 'loading',
              invoke: {
                id: 'getAction',
                src: 'getAction',
                onDone: {
                  actions: 'storeIssuesActionFromProblem',
                  target: '#PERFORM_ACTION',
                },
                onError: [
                  {
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions: 'logErrorFromGetActionService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADING_FAILED: {
              tags: 'loading_failed',
              on: {
                RETRY: {
                  target: 'LOADING',
                },
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              tags: 'loading_problem_context_failed',
              type: 'final',
            },
          },
        },

        REQUEST_INVOICE: {
          id: 'REQUEST_INVOICE',
          tags: 'loading',
          invoke: {
            id: 'doInvoiceRequest',
            src: 'doInvoiceRequest',
            onDone: {
              actions: 'openModalInvoiceRequest',
              target: '#ISSUE_SELECTION',
            },
            onError: {
              actions: [
                'openToastRequestFailed',
                'logErrorFromInvoiceRequestService',
              ],
              target: '#ISSUE_SELECTION',
            },
          },
        },
      },
    },
    {
      actions: {
        addPageData: assign({
          pageData: (
            { pageData },
            event: {
              type: 'done.invoke.getIssues'
              data: IssueListResponse
            },
          ) => [...pageData, { data: event.data, selectedIssues: [] }],
        }),

        decreaseProgressBar: assign({
          progressBarCurrentValue: ({ progressBarCurrentValue }) =>
            progressBarCurrentValue - PROGRESS_BAR_INCREMENT_PERCENTAGE,
        }),

        initActors: assign({
          modalActor: () => spawn(modalCallbackActor, actors.Modal),
          toastActor: () =>
            spawn(toastMachine, { name: actors.Toast, sync: true }),
        }),
        increaseProgressBar: assign({
          progressBarCurrentValue: ({ pageData, progressBarCurrentValue }) => {
            if (pageData.length <= PROGRESS_BAR_MAX_STEPS) {
              return progressBarCurrentValue + PROGRESS_BAR_INCREMENT_PERCENTAGE
            }

            return progressBarCurrentValue
          },
        }),

        logErrorFromInvoiceRequestService: (
          _context: MachineContext,
          event: {
            type: 'error.platform.doInvoiceRequest'
            data: unknown
          },
        ) => {
          logServiceError(
            `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} error on service invoice request`,
            event,
          )
        },
        logErrorFromGetActionService: (_context: MachineContext, event) => {
          logServiceError(
            `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} error on service get action from selected issues`,
            event,
          )
        },
        logErrorFromGetIssueService: (_context: MachineContext, event) => {
          logServiceError(
            `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} error on service get issues`,
            event,
          )
        },

        logUnhandledAction: log(({ issueAction }) => {
          return `[DIAGNOSIS] Unhandled action: { type: ${issueAction?.type}, target: ${issueAction?.target} }`
        }),

        navigateToExternalLink: ({ issueAction }) => {
          void navigateTo(issueAction?.target, { external: true })
        },

        navigateToInsuranceCancellation: ({ orderId, orderlineId }) => {
          void navigateTo({
            name: DASHBOARD.MY_ORDERS.WITHDRAW.INSURANCES,
            params: {
              orderId,
              orderlineId,
            },
          })
        },
        navigateToInsurancePayment: ({ issueAction }) => {
          const issueActionDetails = issueAction?.details as LinkInternal

          if (isEmpty(issueActionDetails.params.policyUuid)) {
            const { logFeatureError } = useSalesCustomerCareLogger()

            logFeatureError({
              errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} navigate to insurance payment due with wrong parameter`,
              errorDetail: {
                availableParams: issueAction?.details,
                targetedPolicyParams: 'policyUuid',
              },
              featureName:
                'diagnosis_navigate_to_insurance_payment_due_with_wrong_parameter_value',
            })
          }

          void navigateTo({
            name: INSURANCE_ROUTES.DUE_PAYMENT,
            params: {
              policyId: issueActionDetails.params.policyUuid,
            },
          })
        },
        navigateToMyOrders: () => {
          void navigateTo({
            name: DASHBOARD.MY_ORDERS.SELF,
          })
        },
        navigateToMySales: () => {
          void navigateTo({
            name: DASHBOARD.MY_SALES,
          })
        },

        navigateToResolutionFlow: ({ orderlineId, pageData }) => {
          const allSelectedCustomerIssues =
            getAllSelectedCustomerIssuesWithAction(pageData)

          const encodedCustomerIssues = encodeIssues(allSelectedCustomerIssues)

          void navigateTo({
            name: CUSTOMER_CARE.RESOLUTION.SELF,
            params: {
              orderlineId,
              customerIssues: encodedCustomerIssues,
            },
          })
        },

        openModalImei: sendTo(
          actors.Modal,
          ({ issueAction }: MachineContext) => {
            const imei = getIMEI(issueAction)

            return {
              type: MODAL_EVENTS_TYPE.OPEN,
              modalName: imei
                ? DIAGNOSIS_MODALS_NAMES.imeiModal
                : DIAGNOSIS_MODALS_NAMES.imeiUnavailableModal,
            }
          },
        ),
        openModalInsurerPortal: sendTo(actors.Modal, {
          type: MODAL_EVENTS_TYPE.OPEN,
          modalName: DIAGNOSIS_MODALS_NAMES.insurerPortalModal,
        }),

        openModalInvoiceRequest: sendTo(actors.Modal, {
          type: MODAL_EVENTS_TYPE.OPEN,
          modalName: DIAGNOSIS_MODALS_NAMES.invoiceRequestModal,
        }),

        openModalInvoiceRequestCanceled: sendTo(actors.Modal, {
          type: MODAL_EVENTS_TYPE.OPEN,
          modalName: DIAGNOSIS_MODALS_NAMES.invoiceRequestModalCanceled,
        }),
        openModalInvoiceRequestPending: sendTo(actors.Modal, {
          type: MODAL_EVENTS_TYPE.OPEN,
          modalName: DIAGNOSIS_MODALS_NAMES.invoiceRequestPendingModal,
        }),
        openModalMobileServiceRedirect: sendTo(
          actors.Modal,
          ({ orderlineId }: MachineContext) => {
            return {
              type: MODAL_EVENTS_TYPE.OPEN,
              modalName: `${CARE_COMMON_MODALS_NAMES.mobileServiceRedirectModal}-${orderlineId}`,
            }
          },
        ),
        openModalPreShippingInvoiceRequest: sendTo(actors.Modal, {
          type: MODAL_EVENTS_TYPE.OPEN,
          modalName: DIAGNOSIS_MODALS_NAMES.preShippingInvoiceRequestModal,
        }),

        openToastRequestFailed: sendTo(actors.Toast, {
          type: TOAST_EVENTS_TYPE.showToast,
          toast: toasts.RequestFailed,
        }),
        removeLastHistoryIssues: assign({
          pageData: ({ pageData }) => pageData.slice(0, -1),
        }),

        resetCurrentSelection: assign({
          issueAction: null,
          pageData: ({ pageData }) => {
            const currentPageData = getCurrentPageData(pageData)

            return [
              ...pageData.slice(0, -1),
              {
                ...currentPageData,
                selectedIssues: [],
              },
            ]
          },
        }),
        storeIssueAction: assign({
          issueAction: ({ pageData }) => {
            const currentPageData = getCurrentPageData(pageData)

            if (currentPageData.selectedIssues) {
              return currentPageData.selectedIssues[0].action
            }

            return null
          },
        }),
        storeIssuesActionFromProblem: assign({
          issueAction: (_, event) => event.data,
        }),
        storeMobilePlanProvider: assign({
          mobileServicePartnerProviderLabel: (_, event) => {
            if (!Array.isArray(event.selectedIssues)) {
              const issueActionDetails = event.selectedIssues?.action
                ?.details as MobilePlanActionDetails

              return issueActionDetails.partner_provider_label ?? null
            }

            return null
          },
          mobileServicePartnerProviderUrl: (_, event) => {
            if (!Array.isArray(event.selectedIssues)) {
              return event.selectedIssues?.action?.target ?? null
            }

            return null
          },
        }),
        storeRootAction: assign({
          issueAction: ({ pageData }) => {
            const currentPageData = getCurrentPageData(pageData)

            return currentPageData.data[0].action
          },
        }),
        storeSelectedIssues: assign({
          pageData: ({ pageData }, event) => {
            const currentPageData = getCurrentPageData(pageData)

            return [
              ...pageData.slice(0, -1),
              {
                ...currentPageData,
                selectedIssues: Array.isArray(event.selectedIssues)
                  ? (event.selectedIssues as IssueSibling[])
                  : ([event.selectedIssues] as IssueSibling[]),
              },
            ]
          },
        }),
      },

      guards: {
        every: (context, event, meta) => {
          const { guards } = meta.cond as MultipleCondType

          return guards.every((guardKey) => {
            const activeGuard = meta.state.machine?.options?.guards?.[guardKey]

            return activeGuard ? activeGuard(context, event, meta) : false
          })
        },

        hasActionOnRootIssue: ({ pageData }) => {
          return !isEmpty(pageData[0].data[0].action)
        },
        hasMultipleIssuesSelected: (_context, event) => {
          if (
            Array.isArray(event.selectedIssues) &&
            event.selectedIssues.length > 1
          ) {
            return true
          }

          return false
        },

        hasSameTargetForAllSelectedIssues: ({ pageData }) => {
          const allSelectedIssuesWithAction =
            getAllSelectedIssuesWithAction(pageData)

          if (allSelectedIssuesWithAction) {
            return allSelectedIssuesWithAction.every(
              (selectedIssue) =>
                selectedIssue?.action?.target ===
                allSelectedIssuesWithAction[0]?.action?.target,
            )
          }

          return false
        },

        hasSelectedIssuesWithActions: ({ pageData }) => {
          const currentPageData = getCurrentPageData(pageData)

          if (currentPageData.selectedIssues) {
            return currentPageData.selectedIssues.every(
              (selectedIssue) => !isEmpty(selectedIssue.action),
            )
          }

          return false
        },

        isExternalRedirectAction: ({ issueAction }) => {
          return issueAction?.type === ISSUE_ACTION_TYPE.externalRedirect
        },

        isInsurerPortalAction: (_context, event) => {
          if (!Array.isArray(event.selectedIssues)) {
            return (
              event.selectedIssues?.action?.type ===
              ISSUE_ACTION_TYPE.insurerPortal
            )
          }

          return false
        },
        isInvalidProblemErrorType: (contex, event) =>
          isInvalidProblemErrorType(contex, event),

        isRedirectToPartnerPortalAction: (_context, event) => {
          if (!Array.isArray(event.selectedIssues)) {
            return (
              event.selectedIssues?.action?.type ===
              ISSUE_ACTION_TYPE.redirectToPartnerPortal
            )
          }

          return false
        },

        isTargetBmWarrantyClaim: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.bmWarrantyClaim
          )
        },

        isTargetGetResolutionOptions: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.getResolutionOptions
          )
        },
        isTargetGetResolutionOptionsWithoutWarrantyExclusion: ({
          issueAction,
        }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target ===
              ISSUE_ACTION_TARGET.getResolutionOptionsNoWarrantyExclusion
          )
        },
        isTargetInsurancePayment: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.insurancePayment
          )
        },
        isTargetInvoiceRequest: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.triggerProcess &&
            issueAction?.target ===
              ISSUE_ACTION_TRIGGER_PROCESS_TARGET.invoiceRequest
          )
        },
        isTargetInvoiceRequestCanceled: (_context, event) => {
          if (!Array.isArray(event.selectedIssues)) {
            return (
              event.selectedIssues?.action?.type ===
                ISSUE_ACTION_TYPE.internalRedirect &&
              event.selectedIssues?.action?.target ===
                ISSUE_ACTION_TARGET.invoiceRequestOrderlineCanceled
            )
          }

          return false
        },
        isTargetInvoiceRequestPending: ({ issueAction }, _, meta) => {
          if (lastEvent(meta?.state)?.skipModal) {
            return (
              issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
              issueAction?.target === ISSUE_ACTION_TARGET.invoiceRequestPending
            )
          }

          return false
        },
        isTargetInvoiceRequestPendingModal: ({ issueAction }, _, meta) => {
          if (lastEvent(meta.state)?.skipModal) {
            return false
          }

          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.invoiceRequestPending
          )
        },
        isTargetInsuranceCancellation: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.insuranceCancellation
          )
        },
        isTargetMyOrders: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.myOrders
          )
        },
        isTargetMySales: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.mySales
          )
        },

        isTargetOrderlineLifecycle: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.orderlineLifecycle
          )
        },

        isTargetPreShippingInvoiceRequest: (_context, event) => {
          if (!Array.isArray(event.selectedIssues)) {
            return (
              event.selectedIssues?.action?.type ===
                ISSUE_ACTION_TYPE.internalRedirect &&
              event.selectedIssues?.action?.target ===
                ISSUE_ACTION_TARGET.invoiceRequestOrderlineNotShipped
            )
          }

          return false
        },
        isTargetRemoteAssistance: ({ issueAction }) => {
          return (
            issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
            issueAction?.target === ISSUE_ACTION_TARGET.remoteAssistance
          )
        },
        isTargetShowImei: ({ issueAction }, event) => {
          // to open the modal
          if (!Array.isArray(event.selectedIssues)) {
            return (
              (event.selectedIssues?.action?.type ===
                ISSUE_ACTION_TYPE.internalRedirect &&
                event.selectedIssues?.action?.target ===
                  ISSUE_ACTION_TARGET.showImei) ||
              // used from within imei modal
              (issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
                issueAction?.target === ISSUE_ACTION_TARGET.showImei)
            )
          }

          return false
        },

        isTargetImeiSelfCare: ({ issueAction }, event) => {
          // to open the modal
          if (!Array.isArray(event.selectedIssues)) {
            return (
              (event.selectedIssues?.action?.type ===
                ISSUE_ACTION_TYPE.internalRedirect &&
                event.selectedIssues?.action?.target ===
                  ISSUE_ACTION_TARGET.imeiSelfcareContent) ||
              // used from within imei modal
              (issueAction?.type === ISSUE_ACTION_TYPE.internalRedirect &&
                issueAction?.target === ISSUE_ACTION_TARGET.imeiSelfcareContent)
            )
          }

          return false
        },

        isRootIssue: ({ pageData }: MachineContext) => {
          if (pageData.length > 1) {
            return false
          }

          if (pageData[0].data.length > 1) {
            return false
          }

          return pageData[0].data[0].customerIssue === CUSTOMER_ISSUE.root
        },

        some: (context, event, meta) => {
          const { guards } = meta.cond as MultipleCondType

          return guards.some((guardKey) => {
            const activeGuard = meta.state.machine?.options?.guards?.[guardKey]

            return activeGuard ? activeGuard(context, event, meta) : false
          })
        },
      },

      services: {
        doInvoiceRequest,
        getAction,
        getIssues: getCustomerIssues,
      },
    },
  )
}
