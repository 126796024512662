import {
  type GetActionQueryParams,
  getProblem,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

import type { MachineContext } from '../Diagnosis.machine.types'
import { getAllSelectedCustomerIssuesWithAction } from '../selectors/getAllSelectedIssues'

export const getAction = async ({
  orderlineId,
  pageData,
}: {
  orderlineId: MachineContext['orderlineId']
  pageData: MachineContext['pageData']
}) => {
  const { logFeatureError } = useSalesCustomerCareLogger()

  const allSelectedCustomerIssuesWithAction =
    getAllSelectedCustomerIssuesWithAction(pageData)

  const params: GetActionQueryParams = {
    customerIssues:
      allSelectedCustomerIssuesWithAction.selectedIssues.join(';'),

    orderlineId: orderlineId as number,
  }

  const payload = await $httpFetch(getProblem, {
    queryParams: {
      ...params,
    },
  })

  if (!isEmpty(payload)) {
    return payload.action
  }

  logFeatureError({
    errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} get action service no action found`,
    errorDetail: {
      orderline_id: params.orderlineId,
      customer_issues: params.customerIssues,
    },
    featureName: 'diagnosis_get_action_service_no_action_found',
  })

  throw new Error('No action found')
}
